import * as React from "react";
import { Link } from "gatsby";

const pageStyles = {
    fontSize: "40px",
    textAlign: "center"
};

const NotFoundPage = () => {
    return (
        <main style={ pageStyles }>
            <h1>Page not found</h1>
            <h2>Go to <Link to={ "/" }>home page</Link></h2>
        </main>
    );
};

export default NotFoundPage;

export const Head = () => <title>Not found</title>;
